<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.smAndDown"
               :fixed="$vuetify.breakpoint.smAndUp" color="primary" app>
        <v-app-bar-nav-icon @click.stop="navToggle">
            <v-icon v-if="$vuetify.breakpoint.smAndDown" class="white--text">mdi-menu</v-icon>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp && drawer" class="white--text">mdi-menu-open</v-icon>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp && !drawer" class="white--text">mdi-menu-open mdi-flip-h</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="toggleFullscreen">
            <v-icon v-if="!isFullscreen" class="white--text">mdi-fullscreen</v-icon>
            <v-icon v-if="isFullscreen" class="white--text">mdi-fullscreen-exit</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-menu open-on-click offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on"
                          size="40" color="white" class="mx-2">
                    <img v-if="userAvatar" :src="userAvatar" :alt="userName">
                    <span v-if="!userAvatar" class="text-body-2 mx-2">{{ userInitials }}</span>
                </v-avatar>
            </template>
            <v-list class="py-0" dense>
                <v-list-item v-if="!$vuetify.breakpoint.smAndUp">
                    <v-list-item-content class="text-center">
                        <v-list-item-title class="white--text text-subtitle-1">{{ userName }}</v-list-item-title>
                        <v-list-item-subtitle class="white--text">{{ userPosition }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider v-if="!$vuetify.breakpoint.smAndUp"></v-divider>
                <v-list-item v-if="!$vuetify.theme.dark" @click="setTheme(true)"
                             class="cursor-pointer">
                    <v-list-item-icon>
                        <v-icon>mdi-moon-waning-crescent</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('switch_to_dark') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$vuetify.theme.dark" @click="setTheme(false)"
                             class="cursor-pointer">
                    <v-list-item-icon>
                        <v-icon>mdi-white-balance-sunny</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('switch_to_light') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(item, i) in items" :key="i" v-show="item.visible"
                             @click.native="item.action ? item.action() : false" href="javascript:void(0)"
                             :to="item.to" ripple :exact="item.exact !== undefined ? item.exact : true">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-card v-if="$vuetify.breakpoint.smAndUp" class="mx-auto" color="transparent" max-width="150" flat outlined>
            <v-list-item dense>
                <v-list-item-content>
                    <v-list-item-title class="white--text">{{ userName }}</v-list-item-title>
                    <v-list-item-subtitle class="white--text">{{ userPosition }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon class="white--text">mdi-web</v-icon>
                </v-btn>
            </template>
            <v-list class="py-0" dense>
                <v-list-item @click="setLang('kk', true)">
                    <v-list-item-title>{{ $t('kazakh') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setLang('ru', true)">
                    <v-list-item-title>{{ $t('russian') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setLang('en', true)">
                    <v-list-item-title>{{ $t('english') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-menu v-model="menuBadge" nudge-bottom="6" min-width="280" max-width="280"
                transition="slide-y-transition" bottom offset-y :close-on-click="closeOnClickMenu">
            <template v-slot:activator="{ on }">
                <v-btn icon slot="activator" v-on="on" class="white--text">
                    <v-badge color="red white--text" :content="notifyUnreadCount"
                             :value="notifyUnreadCount" overlap>
                        <v-icon color="tertiary">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-card v-if="menuBadge" class="overflow-hidden" width="300" max-height="500" elevation="8">
                <v-card-title class="white--text primary py-1">
                    <v-flex class="flex-column justify-space-between">
                        <div class="float-left justify-start">
                            <v-tooltip v-if="notifyType === 'read'" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'read'" v-on="on" class="white--text"
                                           text @click.stop="notifyShow('unread')">
                                        {{ $t('show_unread') }} ({{ notifyUnreadCount }})
                                    </v-btn>
                                </template>
                                <span>{{ $t('show_unread_messages') }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="notifyType === 'unread'" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'unread'" v-on="on" class="white--text"
                                           text @click.stop="notifyShow('read')">
                                        {{ $t('show_read') }} ({{ notifyReadCount }})
                                    </v-btn>
                                </template>
                                <span>{{ $t('show_read_messages') }}</span>
                            </v-tooltip>
                        </div>
                        <div class="float-right justify-end h-30px">
                            <v-tooltip v-if="notifyType === 'read' && notifyItems.length > 0" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'read'" v-on="on" class="white--text"
                                           fab small icon @click.stop="notifyMark('unreadAll')">
                                        <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('mark_all_as_unread') }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="notifyType === 'unread' && notifyItems.length > 0" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'unread'" v-on="on" class="white--text"
                                           fab small icon @click.stop="notifyMark('readAll')">
                                        <v-icon>mdi-checkbox-multiple-marked-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('mark_all_as_read') }}</span>
                            </v-tooltip>
                        </div>
                    </v-flex>
                </v-card-title>
                <v-card-text v-if="notifyItems.length === 0" class="py-3">
                    <div class="text-center justify-content-center subtitle-1 font-weight-medium">
                        {{ $t('no_notifications') }}
                    </div>
                </v-card-text>
                <v-virtual-scroll v-if="notifyItems && notifyItems.length > 0"
                                  :items="notifyItems" :item-height="64" class="virtual-scroll-divider"
                                  :height="(notifyItems.length <= 5) ? (notifyItems.length * 64) : '250'">
                    <template v-slot:default="{ item, index }">
                        <v-list three-line>
                            <v-list-item v-if="item" class="py-0" style="min-height: auto">
                                <v-list-item-content class="py-0" @click="showNotify(item)">
                                    <v-list-item-title class="py-0 text-sm-body-2">
                                        {{ item.title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="py-0 text-caption">
                                        {{ item.message }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="py-0 my-0">
                                    <v-tooltip v-if="item.read_at === null" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-if="item.read_at === null" v-on="on"
                                                   color="white" class="text--primary"
                                                   fab small icon @click.stop="notifyMark('read', item)">
                                                <v-icon>mdi-checkbox-marked-circle-outline mdi-18px</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('mark_as_read') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="item.read_at !== null" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-if="item.read_at !== null" v-on="on"
                                                   color="white" class="text--primary"
                                                   fab small icon @click.stop="notifyMark('unread', item)">
                                                <v-icon>mdi-checkbox-blank-circle-outline mdi-18px</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('mark_as_unread') }}</span>
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-divider v-if="(notifyItems.length - 1) !== index"></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-card>
        </v-menu>
        <v-dialog v-if="dialogNotifyData" v-model="dialogNotify" max-width="500px" persistent eager
                  :retain-focus="false">
            <v-card>
                <v-card-title class="subtitle-1 grey lighten-2" primary-title>
                    {{ dialogNotifyData.title }}
                </v-card-title>
                <v-card-text class="pt-4">
                    {{ dialogNotifyData.message }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" class="mx-3" fab small color="error"
                           @click="notifyDelete('delete', dialogNotifyData)">
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-btn v-show="!$vuetify.breakpoint.xsOnly" color="error" text
                           @click="notifyDelete('delete', dialogNotifyData)">
                        {{ $t('delete') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!dialogNotifyData.read_at" v-show="$vuetify.breakpoint.xsOnly"
                           class="mx-3" fab small color="success"
                           @click="notifyMark('read', dialogNotifyData)">
                        <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                    <v-btn v-if="!dialogNotifyData.read_at" v-show="!$vuetify.breakpoint.xsOnly"
                           color="success" text
                           @click="notifyMark('read', dialogNotifyData)">
                        {{ $t('read') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogNotifyHidden">
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>
<script>

import {mapGetters, mapActions} from "vuex"

export default {
    name: "AppTopMenu",
    inject: [
        'forceRerender',
        'setUserData'
    ],
    props: {
        drawer: Boolean,
    },
    data() {
        return {
            isFullscreen: false,
            menu: false,
            menuBadge: false,
            closeOnClickMenu: true,
            dialogNotify: false,
            dialogNotifyData: [],
            items: [],
            messages: 0,
            notifyItems: [],
            notifications: [],
            transparent: 'rgba(255, 255, 255, 0)',
            settingsScrollbar: {
                suppressScrollX: true,
            },
            notifyType: 'unread',
            notifyReadCount: 0,
            notifyUnreadCount: 0
        }
    },
    computed: {
        ...mapGetters(['lang', 'showNotifyMessages', 'userPosition', 'userName', 'userAvatar']),
    },
    watch: {
        pushMessages(val) {
            if (val) {
                this.$toastr.success(`${val.title} ${val.message}`)
            }
        },
    },
    mounted() {
        this.menuNavigation()
        this.getNotifications()
        this.$auth.load()
            .then(() => {
                if (this.$auth.check() && this.$auth.user()) {
                    this.listenChannelWebsocket()
                }
            })
        this.setLang()
    },
    methods: {
        ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'userInitials', 'setUserPosition', 'setUserAvatar']),
        dialogNotifyHidden() {
            this.dialogNotify = false
            this.dialogNotifyData = []
            this.closeOnClickMenu = true
        },
        navToggle() {
            this.$emit("nav-toggle")
        },
        showNotify(notify) {
            this.dialogNotifyData = notify
            this.closeOnClickMenu = true
            this.dialogNotify = true
        },
        notifyShow(type) {
            this.$nextTick(() => {
                this.notifyType = type
                if (type === 'read') {
                    this.notifyItems = this.notifications.filter(notify => notify.read_at !== null).slice()
                } else {
                    this.notifyItems = this.notifications.filter(notify => notify.read_at === null).slice()
                }
            })
        },
        async getNotifications(action) {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            params.action = action ? action : 'all'
            await this.$http
                .get('admin/notification', {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.notifications = res.body.data

                    this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                    this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)

                    this.notifyShow(this.notifyType)

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_notifications'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async notifyMark(type, notify) {
            if (type) {
                var _this = this
                this.loading = true
                let formData = new FormData()
                formData.append('action', type)
                if (notify && notify.id) {
                    formData.append('notify', notify.id)
                }
                await this.$http
                    .put(`admin/notification/${type}`, formData)
                    .then(res => {
                        if (notify) {
                            const index = this.notifications.indexOf(notify)
                            if (type === 'read') {
                                this.notifications[index].read_at = this.$moment.now()
                            } else if (type === 'unread') {
                                this.notifications[index].read_at = null
                            }
                        } else {
                            if (type === 'readAll') {
                                this.notifications.forEach(function (item, i, arr) {
                                    item.read_at = _this.$moment.now()
                                })
                                this.notifyType = 'read'
                            } else if (type === 'unreadAll') {
                                this.notifications.forEach(function (item, i, arr) {
                                    item.read_at = null
                                })
                                this.notifyType = 'unread'
                            }
                        }

                        this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                        this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)

                        this.notifyShow(this.notifyType)

                        //this.getNotifications()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async notifyDelete(type, notify) {
            if (type) {
                this.loading = true
                let params = {}
                params.action = type
                if (notify && notify.id) {
                    params.notify = notify.id
                }
                await this.$http
                    .delete(`admin/notification/${type}`, {
                        params: params,
                    })
                    .then(res => {
                        if (notify) {
                            const index = this.notifications.indexOf(notify)
                            if (type === 'delete') {
                                this.notifications.splice(index, 1)
                            }
                        } else {
                            if (type === 'deleteAll') {
                                this.notifications = []
                            }
                        }

                        this.notifyShow(this.notifyType)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_deleted'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
            this.dialogNotify = false
            this.dialogNotifyData = []
            this.closeOnClickMenu = true
        },
        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },
        menuNavigation() {
            this.items = [
                {
                    title: 'profile',
                    icon: "mdi-account",
                    to: {
                        name: 'profile'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: 'logout',
                    icon: "mdi-logout-variant",
                    visible: this.$auth.check(),
                    action: this.logout
                }
            ]
        },
        toggleFullscreen: function (event) {
            var elem = document.documentElement
            if (
                document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.msFullscreenEnabled
            ) {
                if (!this.isFullscreen) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.webkitRequestFullscreen) {
                        elem.webkitRequestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen()
                        this.isFullscreen = true
                        return
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                        this.isFullscreen = false
                        return
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                        this.isFullscreen = false
                        return
                    }
                }
            }
        },
        async setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        async setLang(lang, reload) {
            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (lang !== undefined) {
                document.documentElement.lang = lang
                this.$i18n.locale = lang
                this.$vuetify.lang.current = lang
            }
            if (typeof this.$i18n.locale !== undefined) {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== undefined) {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            if (reload) {
                this.forceRerender()
            }
        },
        listenChannelWebsocket() {
            if (this.$auth.check() && window.Echo) {
                window.Echo.private(`App.Models.Admin.${this.$auth.user().ulid}`)
                    /*
                    .listen('.Event.Admin', (event) => {
                        //console.log('AppTopMenu Event.Admin')
                        //console.log(event)
                        if (event.type.indexOf('notify') !== -1) {
                            this.$toastr.success(`${event.title} ${event.message}`)
                        }
                    })
                    */
                    .notification((notify) => {
                        //console.log(notify)
                        console.log('AppTopMenu broadcast notify')
                        if (notify.type.indexOf('broadcast.message') !== -1) {
                            this.$toastr.success(`${notify.title} ${notify.message}`)
                        }
                    })
            }
        },
    }
}
</script>
