import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {layout: 'main', auth: true},
        component: loadView('Dashboard')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: true},
        component: loadView('AdministratorForm')
    },
    {
        path: '/order',
        name: 'order',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
        component: loadView('Orders')
    },
    {
        path: '/profile/activity',
        name: 'profile.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorActivities')
    },
    {
        path: '/administrator',
        name: 'administrator',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Administrators')
    },
    {
        path: '/administrator/create',
        name: 'administrator.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorForm')
    },
    {
        path: '/administrator/:id/edit',
        name: 'administrator.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorForm')
    },
    {
        path: '/push',
        name: 'push',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Pushes')
    },
    {
        path: '/push/create',
        name: 'push.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PushForm')
    },
    {
        path: '/push/:id/edit',
        name: 'push.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PushForm')
    },
    {
        path: '/individual_notification',
        name: 'individual_notification',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('IndividualNotifications')
    },
    {
        path: '/individual_notification/create',
        name: 'individual_notification.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('IndividualNotificationForm')
    },
    {
        path: '/individual_notification/:id/edit',
        name: 'individual_notification.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('IndividualNotificationForm')
    },
    {
        path: '/loyalty',
        name: 'loyalty',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Loyalties')
    },
    {
        path: '/loyalty/create',
        name: 'loyalty.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('LoyaltyForm')
    },
    {
        path: '/loyalty/:id/edit',
        name: 'loyalty.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('LoyaltyForm')
    },
    {
        path: '/administrator/:id/activity',
        name: 'administrator.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorActivities')
    },
    {
        path: '/partner',
        name: 'partner',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Partners')
    },
    {
        path: '/partner/create',
        name: 'partner.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/partner/:id/edit',
        name: 'partner.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/partner/:id/activity',
        name: 'partner.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PartnerActivities')
    },
    {
        path: '/client',
        name: 'client',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Clients')
    },
    {
        path: '/client/create',
        name: 'client.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ClientForm')
    },
    {
        path: '/client/:id/edit',
        name: 'client.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ClientForm')
    },
    {
        path: '/client/:id/activity',
        name: 'client.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ClientActivities')
    },
    {
        path: '/company',
        name: 'company',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Companies')
    },
    {
        path: '/company/create',
        name: 'company.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CompanyForm')
    },
    {
        path: '/company/:id/edit',
        name: 'company.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CompanyForm')
    },
    {
        path: '/brand',
        name: 'brand',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Brands')
    },
    {
        path: '/brand/create',
        name: 'brand.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('BrandForm')
    },
    {
        path: '/brand/:id/edit',
        name: 'brand.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('BrandForm')
    },
    {
        path: '/certificate',
        name: 'certificate',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Certificates')
    },
    {
        path: '/certificate/create',
        name: 'certificate.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CertificateForm')
    },
    {
        path: '/certificate/:id/edit',
        name: 'certificate.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CertificateForm')
    },
    {
        path: '/banner',
        name: 'banner',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Banners')
    },
    {
        path: '/banner/create',
        name: 'banner.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('BannerForm')
    },
    {
        path: '/banner/:id/edit',
        name: 'banner.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('BannerForm')
    },
    {
        path: '/art_category',
        name: 'art_category',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ArtCategories')
    },
    {
        path: '/art_category/create',
        name: 'art_category.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ArtCategoryForm')
    },
    {
        path: '/art_category/:id/edit',
        name: 'art_category.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ArtCategoryForm')
    },
    {
        path: '/art/:art_category_id',
        name: 'art',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Art')
    },
    {
        path: '/art/:art_category_id/create',
        name: 'art.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ArtForm')
    },
    {
        path: '/art/:art_category_id/:id/edit',
        name: 'art.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ArtForm')
    },
    {
        path: '/type_certificate',
        name: 'type_certificate',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeCertificates')
    },
    {
        path: '/type_certificate/create',
        name: 'type_certificate.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeCertificateForm')
    },
    {
        path: '/type_certificate/:id/edit',
        name: 'type_certificate.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeCertificateForm')
    },
    {
        path: '/news',
        name: 'news',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('News')
    },
    {
        path: '/news/create',
        name: 'news.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('NewsForm')
    },
    {
        path: '/news/:id/edit',
        name: 'news.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('NewsForm')
    },
    {
        path: '/page',
        name: 'page',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Pages')
    },
    {
        path: '/page/create',
        name: 'page.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PageForm')
    },
    {
        path: '/page/:id/edit',
        name: 'page.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PageForm')
    },
    {
        path: '/sex',
        name: 'sex',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Sexes')
    },
    {
        path: '/sex/create',
        name: 'sex.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SexForm')
    },
    {
        path: '/sex/:id/edit',
        name: 'sex.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SexForm')
    },
    {
        path: '/shopping_center_service',
        name: 'shopping_center_service',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ShoppingCenterServices')
    },
    {
        path: '/shopping_center_service/create',
        name: 'shopping_center_service.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ShoppingCenterServiceForm')
    },
    {
        path: '/shopping_center_service/:id/edit',
        name: 'shopping_center_service.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ShoppingCenterServiceForm')
    },
    {
        path: '/type_company',
        name: 'type_company',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeCompanies')
    },
    {
        path: '/type_company/create',
        name: 'type_company.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeCompanyForm')
    },
    {
        path: '/type_company/:id/edit',
        name: 'type_company.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeCompanyForm')
    },

    {
        path: '/day_week',
        name: 'day_week',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('DayWeeks')
    },
    {
        path: '/day_week/create',
        name: 'day_week.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('DayWeekForm')
    },
    {
        path: '/day_week/:id/edit',
        name: 'day_week.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('DayWeekForm')
    },
    {
        path: '/chain',
        name: 'chain',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Chains')
    },
    {
        path: '/chain/create',
        name: 'chain.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ChainForm')
    },
    {
        path: '/chain/:id/edit',
        name: 'chain.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ChainForm')
    },
    {
        path: '/fitness',
        name: 'fitness',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessForm')
    },
    {
        path: '/trainer',
        name: 'trainer',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Trainers')
    },
    {
        path: '/trainer/create',
        name: 'trainer.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TrainerForm')
    },
    {
        path: '/trainer/:id/edit',
        name: 'trainer.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TrainerForm')
    },
    {
        path: '/fitness_main',
        name: 'fitness_main',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessMains')
    },
    {
        path: '/fitness_main/create',
        name: 'fitness_main.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessMainForm')
    },
    {
        path: '/fitness_main/:id/edit',
        name: 'fitness_main.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessMainForm')
    },
    {
        path: '/group_lesson',
        name: 'group_lesson',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('GroupLessons')
    },
    {
        path: '/group_lesson/create',
        name: 'group_lesson.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('GroupLessonForm')
    },
    {
        path: '/group_lesson/:id/edit',
        name: 'group_lesson.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('GroupLessonForm')
    },
    {
        path: '/type_service',
        name: 'type_service',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeServices')
    },
    {
        path: '/type_service/create',
        name: 'type_service.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeServiceForm')
    },
    {
        path: '/type_service/:id/edit',
        name: 'type_service.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeServiceForm')
    },
    {
        path: '/fitness_calendar',
        name: 'fitness_calendar',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessCalendars')
    },
    {
        path: '/fitness_calendar/create',
        name: 'fitness_calendar.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessCalendarForm')
    },
    {
        path: '/fitness_calendar/:id/edit',
        name: 'fitness_calendar.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FitnessCalendarForm')
    },
    {
        path: '/price_subscription',
        name: 'price_subscription',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PriceSubscriptions')
    },
    {
        path: '/price_subscription/create',
        name: 'price_subscription.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PriceSubscriptionForm')
    },
    {
        path: '/price_subscription/:id/edit',
        name: 'price_subscription.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PriceSubscriptionForm')
    },
    {
        path: '/time_subscription',
        name: 'time_subscription',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TimeSubscriptions')
    },
    {
        path: '/time_subscription/create',
        name: 'time_subscription.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TimeSubscriptionForm')
    },
    {
        path: '/time_subscription/:id/edit',
        name: 'time_subscription.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TimeSubscriptionForm')
    },
    {
        path: '/subscription_name',
        name: 'subscription_name',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SubscriptionNames')
    },
    {
        path: '/subscription_name/create',
        name: 'subscription_name.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SubscriptionNameForm')
    },
    {
        path: '/subscription_name/:id/edit',
        name: 'subscription_name.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SubscriptionNameForm')
    },
    {
        path: '/type_subscription',
        name: 'type_subscription',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeSubscriptions')
    },
    {
        path: '/type_subscription/create',
        name: 'type_subscription.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeSubscriptionForm')
    },
    {
        path: '/type_subscription/:id/edit',
        name: 'type_subscription.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeSubscriptionForm')
    },

    {
        path: '/subscription_property',
        name: 'subscription_property',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SubscriptionProperties')
    },
    {
        path: '/subscription_property/create',
        name: 'subscription_property.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SubscriptionPropertyForm')
    },
    {
        path: '/subscription_property/:id/edit',
        name: 'subscription_property.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SubscriptionPropertyForm')
    },


    {
        path: '/car_energy',
        name: 'car_energy',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'moderator']}},
        component: loadView('CarEnergy')
    },

    {
        path: '/shop',
        name: 'shop',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'moderator']}},
        component: loadView('Shops')
    },
    {
        path: '/shop/create',
        name: 'shop.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'moderator']}},
        component: loadView('ShopForm')
    },
    {
        path: '/shop/:id/edit',
        name: 'shop.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'moderator']}},
        component: loadView('ShopForm')
    },
    {
        path: '/category_shop',
        name: 'category_shop',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryShops')
    },
    {
        path: '/category_shop/create',
        name: 'category_shop.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryShopForm')
    },
    {
        path: '/category_shop/:id/edit',
        name: 'category_shop.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryShopForm')
    },
    {
        path: '/category_product',
        name: 'category_product',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryProducts')
    },
    {
        path: '/category_product/:category_shop',
        name: 'category_products_filter',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryProducts')
    },
    {
        path: '/category_product/create',
        name: 'category_product.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryProductForm')
    },
    {
        path: '/category_product/:id/edit',
        name: 'category_product.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CategoryProductForm')
    },

    {
        path: '/product',
        name: 'product',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Products')
    },
    {
        path: '/product/create',
        name: 'product.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ProductForm')
    },
    {
        path: '/product/:id/edit',
        name: 'product.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ProductForm')
    },
    {
        path: '/food',
        name: 'food',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Food')
    },
    {
        path: '/coffee_house',
        name: 'coffee_house',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHouses')
    },
    {
        path: '/coffee_house/create',
        name: 'coffee_house.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHousesForm')
    },
    {
        path: '/coffee_house/:id/edit',
        name: 'coffee_house.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHousesForm')
    },

    {
        path: '/menu_category/coffee_house/:coffee_house_id',
        name: 'menu_category_coffee_house',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuCategories')
    },
    {
        path: '/menu_category/create/coffee_house/:coffee_house_id',
        name: 'menu_category_coffee_house.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuCategoryForm')
    },
    {
        path: '/menu_category/:id/edit/coffee_house/:coffee_house_id',
        name: 'menu_category_coffee_house.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuCategoryForm')
    },

    {
        path: '/menu_category/restaurant/:restaurant_id',
        name: 'menu_category_restaurant',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuCategories')
    },
    {
        path: '/menu_category/create/restaurant/:restaurant_id',
        name: 'menu_category_restaurant.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuCategoryForm')
    },
    {
        path: '/menu_category/:id/edit/restaurant/:restaurant_id',
        name: 'menu_category_restaurant.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuCategoryForm')
    },

    {
        path: '/menu_category/restaurant/:restaurant_id/category/:category_id/menu',
        name: 'restaurant_menu',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Menu')
    },
    {
        path: '/menu_category/restaurant/:restaurant_id/category/:category_id/menu/create',
        name: 'restaurant_menu.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuForm')
    },
    {
        path: '/menu_category/restaurant/:restaurant_id/category/:category_id/menu/:menu_id/edit',
        name: 'restaurant_menu.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuForm')
    },

    {
        path: '/menu_category/coffee_house/:coffee_house_id/category/:category_id/menu',
        name: 'coffee_house_menu',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Menu')
    },
    {
        path: '/menu_category/coffee_house/:coffee_house_id/category/:category_id/menu/create',
        name: 'coffee_house_menu.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuForm')
    },
    {
        path: '/menu_category/coffee_house/:coffee_house_id/category/:category_id/menu/:menu_id/edit',
        name: 'coffee_house_menu.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('MenuForm')
    },

    {
        path: '/coffee_house_tables',
        name: 'coffee_house_tables',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHouseTables')
    },
    {
        path: '/coffee_house_table/create',
        name: 'coffee_house_table.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHouseTableForm')
    },
    {
        path: '/coffee_house_table/:id/edit',
        name: 'coffee_house_table.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHouseTableForm')
    },

    {
        path: '/coffee_house_reservations',
        name: 'coffee_house_reservations',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHouseReservations')
    },
    {
        path: '/coffee_house_menu_pdf/:id',
        name: 'coffee_house_menu_pdf',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CoffeeHouseMenu')
    },

    {
        path: '/restaurant',
        name: 'restaurant',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Restaurants')
    },
    {
        path: '/restaurant/create',
        name: 'restaurant.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantForm')
    },
    {
        path: '/restaurant/:id/edit',
        name: 'restaurant.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantForm')
    },

    {
        path: '/restaurant_table',
        name: 'restaurant_table',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantTables')
    },
    {
        path: '/restaurant_table/create',
        name: 'restaurant_table.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantTableForm')
    },
    {
        path: '/restaurant_table/:id/edit',
        name: 'restaurant_table.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantTableForm')
    },

    {
        path: '/restaurant_reservation',
        name: 'restaurant_reservation',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantReservations')
    },
    {
        path: '/restaurant_menu_pdf/:id',
        name: 'restaurant_menu_pdf',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('RestaurantMenu')
    },
    {
        path: '/cinema',
        name: 'cinema',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Cinema')
    },
    {
        path: '/mall',
        name: 'mall',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Mall')
    },
    {
        path: '/park',
        name: 'park',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Park')
    },
    {
        path: '/attraction',
        name: 'attraction',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Attractions')
    },
    {
        path: '/attraction/create',
        name: 'attraction.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AttractionForm')
    },
    {
        path: '/attraction/:id/edit',
        name: 'attraction.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AttractionForm')
    },
    {
        path: '/park_restaurant',
        name: 'park_restaurant',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ParkRestaurantForm')
    },
    {
        path: '/park_restaurant_menu',
        name: 'park_restaurant_menu',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('ParkRestaurantMenu')
    },

    {
        path: '/faq/:type',
        name: 'faq',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Faqs')
    },
    {
        path: '/faq/:type/create',
        name: 'faq.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FaqForm')
    },
    {
        path: '/faq/:id/:type/edit',
        name: 'faq.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FaqForm')
    },

    {
        path: '/type_tables',
        name: 'type_tables',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeTables')
    },
    {
        path: '/type_table/create',
        name: 'type_table.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeTablesForm')
    },
    {
        path: '/type_table/:id/edit',
        name: 'type_table.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('TypeTablesForm')
    },

    {
        path: '/auth/login',
        name: 'login',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },

    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Forgot')
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/ResetPassword')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/NotFound')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Forbidden')
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Blocked')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Maintenance')
    },
    {
        path: '*',
        redirect: '/404',
        meta: {layout: 'default', auth: undefined},
    },
];

Vue.router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    /*
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return window.goTo(scrollTo)
    },
    */
    /*
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    */
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default Vue.router;
